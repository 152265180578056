




































import {
  computed,
  defineComponent,
  PropType,
  useContext,
  useRouter
} from '@nuxtjs/composition-api';
import { SfNotification } from '@storefront-ui/vue';

import type { UiNotification } from '~/composables/useUiNotification/useUiNotification';
import { useCategoryStore } from '~/modules/catalog/category/stores/category';

export default defineComponent({
  name: 'InfoNotification',
  components: {
    SfNotification
  },
  props: {
    notificationsTop: {
      required: true,
      type: Array as PropType<UiNotification[]>
    }
  },
  setup(props) {
    const {
      app: { $gtm },
      localePath
    } = useContext();

    const categoryStore = useCategoryStore();

    const notificationsByArea = computed(
      () => props.notificationsTop as UiNotification[]
    );

    const wrapperClasses = computed(() => {
      return [
        {
          active: notificationsByArea.value.length > 0,
          'collection-page': categoryStore?.categoryType === 'collection'
        },
        'area-top'
      ];
    });

    const close = (notification) => {
      $gtm.push({
        event: 'click_close_popin'
      });

      if (typeof notification === 'function') {
        notification();
      }
    };

    const router = useRouter();

    const onNotificationClick = (notification) => {
      if (notification.action_type === 'add_all_to_cart') {
        router.push(localePath('/cart'));
        return;
      }
      return;
    };

    return {
      onNotificationClick,
      wrapperClasses,
      notificationsByArea,
      close
    };
  }
});
