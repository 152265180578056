











































import {
  computed,
  defineComponent,
  onMounted,
  useContext
} from '@nuxtjs/composition-api';
import { SfModal } from '@storefront-ui/vue';

import { useConfig, useStore, useUiState } from '~/composables';
import type { LocaleConfig } from '~/composables/useStore/useStore';
import { isCookieExist, setCookie } from '~/diptyqueTheme/helpers/cookieHelper';
import { useIPData } from '~/diptyqueTheme/composable/useIPData';
import { redirectConfig } from '~/diptyqueTheme/config/countriesRedirectConfig';

export default defineComponent({
  name: 'VaimoRedirectModal',
  components: {
    SfModal,
    VaimoButton: () => import('atoms/VaimoButton.vue')
  },
  setup() {
    const {
      app: {
        i18n: {
          localeProperties: { code: storeCode, websiteName }
        }
      }
    } = useContext();
    const { isRedirectModalOpen, toggleRedirectModal } = useUiState();
    const { handleStoreSwitchAndRedirect } = useStore();
    const { userCountry } = useIPData();
    const { config } = useConfig();
    const { app: { i18n: { locales } } = { i18n: { locales: [] } } } =
      useContext();

    const COOKIE_KEY = 'vsf-redirect-modal-showed';
    const ONE_SECOND = 1000;

    const isStoreHk = storeCode === 'zh_hk';

    const storeToRedirect = computed(() =>
      getTargetStoreName(userCountry.value)
    );

    onMounted(() => handleRedirect(storeCode, userCountry.value));

    const getTargetStoreName = (country: string): string => {
      return redirectConfig[country]?.store ?? redirectConfig.default.store;
    };

    const calculateExpirationDate = (lifetimeInSeconds: number): Date => {
      return new Date(Number(new Date()) + lifetimeInSeconds * ONE_SECOND);
    };

    const handleRedirect = (storeCode: string, country: string) => {
      const redirectToCode = getTargetStoreCode(country);

      if (shouldRedirect(storeCode, redirectToCode)) {
        toggleRedirectModal();
      }
    };

    const getTargetStoreCode = (country: string): string => {
      return (
        redirectConfig[country]?.redirectTo ?? redirectConfig.default.redirectTo
      );
    };

    const shouldRedirect = (
      currentStore: string,
      targetStore: string
    ): boolean => {
      return currentStore !== targetStore && !isCookieExist(COOKIE_KEY);
    };

    const closePopupHandler = (): void => {
      toggleRedirectModal();
      setCookie(
        COOKIE_KEY,
        'true',
        calculateExpirationDate(config.value.web_cookie_cookie_lifetime)
      );
    };

    const redirectToStore = async () => {
      const targetCountryCode = getTargetStoreCode(userCountry.value);
      const targetStore = locales.find(
        (locale: LocaleConfig) => locale.code === targetCountryCode
      );

      closePopupHandler();
      await handleStoreSwitchAndRedirect(targetStore);
    };

    return {
      isRedirectModalOpen,
      redirectToStore,
      storeToRedirect,
      onClose: closePopupHandler,
      websiteName: websiteName.toLowerCase(),
      isStoreHk
    };
  }
});
