













































































































import {
  computed,
  defineComponent,
  PropType,
  useContext,
  useRouter,
  watch
} from '@nuxtjs/composition-api';
import { SfNotification, SfOverlay } from '@storefront-ui/vue';
import VaimoButton from 'atoms/VaimoButton.vue';

import { useConfig, useUiNotification } from '~/composables';
import type { UiNotification } from '~/composables/useUiNotification/useUiNotification';
import { useUiState } from '~/composables/useUiState';
import { validateFraction } from '~/diptyqueTheme/helpers/priceFormatter';
import productGetters from '~/modules/catalog/product/getters/productGetters';
import { useProductStore } from '~/modules/catalog/product/stores/product';
import useCart from '~/modules/checkout/composables/useCart';

export default defineComponent({
  name: 'AddToCartNotification',
  components: {
    VaimoButton,
    VaimoBanner: () => import('atoms/VaimoBanner.vue'),
    SfNotification,
    SfOverlay
  },
  props: {
    notifications: {
      required: true,
      type: Array as PropType<UiNotification[]>
    }
  },
  setup(props) {
    const {
      app: { $gtm },
      localePath
    } = useContext();

    const notificationsByArea = computed(
      () => props.notifications as UiNotification[]
    );

    const getProductPrice = (product): number => {
      if (product?.dynamic_price) {
        return product?.dynamic_price;
      }
      return productGetters.getPrice(product).regular || 0;
    };

    const productStore = useProductStore();

    const isEngravingInfo = computed(() => {
      return productStore.isEngravingInfoProcessed;
    });

    const wrapperClasses = computed(() => {
      return [
        {
          active: notificationsByArea.value.length > 0
        },
        'area-default'
      ];
    });

    const getProductImage = (notification): string | undefined => {
      if (notification.additional_info.product?.thumbnail?.url) {
        return notification.additional_info.product.thumbnail.url;
      } else if (notification.additional_info.product?.small_image?.url) {
        return notification.additional_info.product?.small_image?.url;
      }
    };

    const router = useRouter();

    const proceedToCheckout = (close: () => void): void => {
      router.push(localePath('/cart'));
      close();
      window.scrollTo(0, 0);
    };

    const { notifications, isVisible } = useUiNotification();

    const onClickOutSide = (close: () => void): void => {
      if (!isVisible.value) {
        return;
      }
      close();
    };

    const visibilityState = computed(() => {
      return isVisible.value;
    });

    const { isEngravingInfoOpen, hideEngravingInfo } = useUiState();
    watch(
      () => visibilityState.value,
      (newValue: boolean) => {
        if (
          isEngravingInfoOpen &&
          newValue === false &&
          productStore.isEngravingInfoProcessed === true
        ) {
          hideEngravingInfo();
          productStore.isEngravingInfoProcessed = false;
        }
      }
    );

    const { app } = useContext();
    const { cart } = useCart();
    const { config } = useConfig();

    const freeShippingConfig = computed(() => ({
      icon: config.value?.checkout_sidebar_free_shipping_icon,
      leftText: config.value?.checkout_sidebar_free_shipping_left_text,
      textFree: config.value?.checkout_sidebar_free_shipping_text,
      displayFree: config.value?.checkout_sidebar_display_free_shipping_section
    }));

    const getConfigValue = (
      key: keyof typeof freeShippingConfig.value
    ): string | boolean | null => {
      const value = freeShippingConfig.value[key];
      return value !== undefined ? value : null;
    };

    const getFreeShippingThresholdInfo = computed(() => {
      return cart.value?.freeshipping_threshold_info;
    });

    const isFreeShippingEnabled = computed(() => {
      const thresholdInfo = getFreeShippingThresholdInfo.value;
      return thresholdInfo ? thresholdInfo.freeshipping_enabled : false;
    });

    const getFreeShippingThresholdInfoText = computed(() => {
      const thresholdInfo = getFreeShippingThresholdInfo.value;
      const leftTextTemplate = getConfigValue('leftText');

      if (
        typeof leftTextTemplate === 'string' &&
        thresholdInfo?.freeshipping_left !== 0
      ) {
        const combinedAmount = `${thresholdInfo.currency_symbol} ${thresholdInfo.freeshipping_left}`;
        const formattedText = leftTextTemplate.replace('%s', combinedAmount);

        return app.i18n.t(formattedText);
      }

      return getConfigValue('textFree') as string;
    });

    const showFreeShippingSection = computed(() => {
      const displayFree = getConfigValue('displayFree');

      return isFreeShippingEnabled.value && displayFree === true;
    });

    const close = (notification: () => void): void => {
      $gtm.push({
        event: 'click_close_popin'
      });

      if (typeof notification === 'function') {
        notification();
      }
    };

    return {
      notifications,
      onClickOutSide,
      wrapperClasses,
      notificationsByArea,
      close,
      validateFraction,
      getProductImage,
      getProductPrice,
      isEngravingInfo,
      proceedToCheckout,
      isVisible,
      getConfigValue,
      getFreeShippingThresholdInfoText,
      showFreeShippingSection
    };
  }
});
