







































import {
  defineComponent,
  ref,
  useContext,
  useFetch
} from '@nuxtjs/composition-api';

import { useContentfulGQL } from '~/integrations/contentful/src/composables/useContentfulGQL';

export default defineComponent({
  name: 'VaimoBenefits',
  components: {
    VaimoButton: () => import('atoms/VaimoButton.vue')
  },
  props: {
    bunchKey: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const { query, queryResponse } = useContentfulGQL('byIdBenefits');
    const iconItems = ref([]);
    const title = ref('');
    const subTitle = ref('');
    const buttonText = ref('');
    const buttonLink = ref('');
    const {
      app: { i18n }
    } = useContext();
    const ICON_PREFIX = 'icon:';

    useFetch(async () => {
      await query('getMicroDataBunchByKey', {
        key: props.bunchKey,
        locale: i18n.localeProperties.contentfulLocale
      });
      const items =
        queryResponse.value?.microdataBunchCollection?.items?.[0]?.entriesCollection?.items.filter(
          (i) => i
        ) || [];

      iconItems.value = items.reduce((memo, { key, value, sys }) => {
        if (key.startsWith(ICON_PREFIX)) {
          const item = {
            key: key.slice(ICON_PREFIX.length),
            value,
            id: sys?.id || key
          };
          memo.push(item);
        }
        return memo;
      }, []);

      const variablesMap = {
        'benefits-title': title,
        'benefits-subtitle': subTitle,
        'benefits-button-text': buttonText,
        'benefits-button-link': buttonLink
      };

      if (items && items.length) {
        items.forEach((item) => {
          const element = variablesMap[item.key];
          if (element) {
            element.value = item.value;
          }
        });
      }
    });

    return {
      iconItems,
      title,
      subTitle,
      buttonText,
      buttonLink
    };
  }
});
