

































































import {
  computed,
  defineComponent,
  ref,
  useContext
} from '@nuxtjs/composition-api';
import { SfLink } from '@storefront-ui/vue';
import { onSSR } from '@vue-storefront/core';
import VaimoMenuLayer from 'organisms/header/VaimoMenuLayer.vue';

import { useLink } from '~/diptyqueTheme/composable/useLink';
import { useMegaMenuMobileStore } from '~/diptyqueTheme/stores/megaMenuMobile';
import { useContentfulGQL } from '~/integrations/contentful/src/composables/useContentfulGQL';

export default defineComponent({
  name: 'VaimoTopNavigation',
  components: {
    VaimoMenuLayer,
    SfLink
  },

  setup() {
    const { query, queryResponse: megaMenuEntries } =
      useContentfulGQL('byIdTopNavigation');
    const ctx = useContext();
    const {
      app: { $gtm }
    } = useContext();

    const isMenuLayerVisible = ref<boolean>(false);
    const currentActiveLinkID = ref<string>('');
    const currentActiveLinkName = ref<string>('');
    const currentFocusedLinkID = ref<string>('');
    const isHovering = ref(false);
    const megaMenuStore = useMegaMenuMobileStore();
    const { normalizeLink, isInternal } = useLink();

    const getMenuLayersList = computed<object[]>(() => {
      const items =
        megaMenuEntries.value?.megaMenuCollection?.items[0]?.layersCollection
          ?.items ?? [];

      return items.reduce((memo, cur) => {
        if (cur && cur.layerContentCollection?.items?.length) {
          memo.push({
            id: cur.sys?.id,
            data: [...cur.layerContentCollection.items],
            pushBlocksWidth: cur.pushWidth
          });
        }

        return memo;
      }, []);
    });

    const getFilteredMenuLayersList = (id: string) => {
      const list = getMenuLayersList.value;

      return list.filter(
        (layer: { id: string; data: object[] }) => layer.id === id
      );
    };

    const changeNav = (id: string, name: string): void => {
      isHovering.value = true;

      const filteredMenuLayersList = getFilteredMenuLayersList(id);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const layerHasContent = !!filteredMenuLayersList?.[0]?.data?.filter(
        (block) => block
      ).length;

      currentActiveLinkID.value = id;
      currentActiveLinkName.value = name;
      isMenuLayerVisible.value = layerHasContent;
      megaMenuStore.isDesktopMenuOpen = layerHasContent;
    };

    const removeHover = () => {
      isHovering.value = false;
    };

    const setFocus = (id: string) => {
      currentFocusedLinkID.value = id;
    };

    const removeFocus = () => {
      currentFocusedLinkID.value = '';
    };

    const getNavigation = computed<object[]>(() => {
      const items =
        megaMenuEntries.value?.megaMenuCollection?.items[0]?.layersCollection
          ?.items ?? [];

      return items
        .filter((item) => item.topMenuItemText)
        .map((item) => ({
          text: item.topMenuItemText,
          link: item.topMenuItemLink,
          id: item.sys?.id,
          children: getFilteredMenuLayersList(item.sys?.id)
        }));
    });

    const hideMenuLayerBlock = (): void => {
      isMenuLayerVisible.value = false;
      megaMenuStore.isDesktopMenuOpen = false;
    };

    onSSR(async () => {
      await query('getMegaMenu', {
        type: 'Header',
        storeview: ctx.app.i18n.localeProperties.storeview
      });
    });

    const getClickNavDetails = (menuItemName) => {
      $gtm.push({
        event: 'clickNav',
        mainLevel: menuItemName
      });
    };

    const hasSubcategories = (item) => {
      return item.children[0].data &&
        item.children[0].data.filter((block) => block).length > 0
        ? 'true'
        : 'false';
    };

    return {
      changeNav,
      getNavigation,
      hideMenuLayerBlock,
      removeHover,
      currentActiveLinkID,
      currentActiveLinkName,
      isMenuLayerVisible,
      getClickNavDetails,
      megaMenuEntries,
      isHovering,
      normalizeLink,
      isInternal,
      hasSubcategories,
      removeFocus,
      setFocus,
      currentFocusedLinkID
    };
  }
});
