






























































































import {
  computed,
  defineComponent,
  onBeforeMount,
  ref,
  useContext
} from '@nuxtjs/composition-api';
import type { MetaInfo } from 'vue-meta';

import { useNewsletter, useUiNotification, useUser } from '~/composables';
import termsOfServiceLinks from '~/diptyqueTheme/config/termsOfServiceLinks';
import { useCustomerStore } from '~/modules/customer/stores/customer';

export default defineComponent({
  name: 'AccountSubscriptions',
  components: {
    VaimoCheckbox: () => import('molecules/VaimoCheckbox.vue'),
    VaimoButton: () => import('atoms/VaimoButton.vue'),
    VaimoBenefits: () => import('organisms/VaimoBenefits.vue')
  },
  setup() {
    const customerStore = useCustomerStore();
    const { updateSubscription, loading } = useNewsletter();
    const { send: sendNotification } = useUiNotification();
    const { app } = useContext();
    const { load: loadUser } = useUser();
    const storeCode = app.i18n.localeProperties.code;
    const isStoreDe = storeCode === 'de_eu';
    const isEmailType = ref(false);
    const isSmsType = ref(false);
    const isPostType = ref(false);
    const errorMessage = ref('');
    const isNewsletterConfirmation = ref(false);
    const showActivateNlValidation = computed(() => {
      return isStoreDe && isEmailType.value;
    });
    const DISPLAY_TIME = 5000;
    const handleSubscribe = async () => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      const { email, mobile } = customerStore.user;
      if (!mobile && isSmsType.value) {
        errorMessage.value = app.i18n.t(
          'Add your phone number for SMS subscription!'
        ) as string;
        setTimeout(() => {
          errorMessage.value = '';
        }, DISPLAY_TIME);
        return;
      }
      try {
        await updateSubscription({
          email: email,
          customQuery: {
            subscribeEmailToNewsletter: 'subscriptionMutation',
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            metadata: {
              optin_mail: isPostType.value,
              optin_sms: isSmsType.value,
              optin_newsletter: isEmailType.value
            }
          }
        });
        sendNotification({
          id: Symbol('subscribed'),
          message: app.i18n.t('You saved the account information') as string,
          type: 'success',
          persist: false
        });
      } catch (e) {
        console.error(e);
      }
    };

    const newsletterValidation = computed(() => {
      if (!isStoreDe) return true;
      if (isEmailType.value && !isNewsletterConfirmation.value) return false;
      return true;
    });

    onBeforeMount(async () => {
      await loadUser({
        customQuery: {
          customer: 'customerWithOptions'
        }
      });
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      const { optin_mail, optin_sms, optin_newsletter } = customerStore.user;
      isPostType.value = optin_mail;
      isSmsType.value = optin_sms;
      isEmailType.value = optin_newsletter;
    });

    const selectType = (type) => {
      if (type === 'email') {
        isEmailType.value = !isEmailType.value;
      } else if (type === 'sms') {
        isSmsType.value = !isSmsType.value;
      } else if (type === 'post') {
        isPostType.value = !isPostType.value;
      } else if (type === 'newsletterConfirmation') {
        isNewsletterConfirmation.value = !isNewsletterConfirmation.value;
      }
    };

    const getTermsOfServiceLinks = computed(() => {
      return termsOfServiceLinks[app.i18n.locale];
    });

    return {
      isEmailType,
      isSmsType,
      isPostType,
      handleSubscribe,
      selectType,
      errorMessage,
      loading,
      showActivateNlValidation,
      isNewsletterConfirmation,
      newsletterValidation,
      getTermsOfServiceLinks,
      isStoreDe
    };
  },
  head(): MetaInfo {
    const pageTitle = this.$i18n.t(this.$route?.meta?.titleLabel ?? '');
    return {
      title: pageTitle as string
    };
  }
});
